<template>
<h1 class="text-center">Select</h1>
</template>

<script>
export default {
  name: "Select"
}
</script>

<style scoped>

</style>